import { useReportDownload } from '../download-report/hooks/useReportDownload'
import { useSendIncidentsToOntic } from '../hooks/useSendIncidentsToOntic'
import { FeatureFlag, useFeatureFlag } from '@/hooks/useFeatureFlags'
import { usePermissions } from '@/hooks/usePermissions'
import { CiLink } from 'react-icons/ci'
import { HiOutlineDotsHorizontal, HiOutlineDownload } from 'react-icons/hi'
import { IoIosSend } from 'react-icons/io'

import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  Spinner,
  Tooltip,
} from '@chakra-ui/react'

import { MenuSelectItem } from '@/components/ui'
import { IncidentQuery } from '@/graphql/generated/operations'

import { getOnticTooltipMessage } from '../utils/helpers'

export interface AdditionalOptionsButtonIProps {
  incident: IncidentQuery['incident']
  isDownloadReportEnabled: boolean
  isSendToOnticEnabled: boolean
  onAssociateCaseModalOpen: () => void
}

export const AdditionalOptionsButton = ({
  incident,
  isDownloadReportEnabled,
  isSendToOnticEnabled,
  onAssociateCaseModalOpen,
}: AdditionalOptionsButtonIProps) => {
  const { downloadReport } = useReportDownload({ incident })
  const { send, isSendingToOntic } = useSendIncidentsToOntic(incident?.id)
  const isCaseManagementEnabled = useFeatureFlag(FeatureFlag.caseManagement)
  const isSendToOnticFeatureEnabled = useFeatureFlag(FeatureFlag.sendToOntic)
  const { shouldEnableCaseManagement, shouldEnableSendToOntic } =
    usePermissions()
  const shouldShowAssociateIncident =
    isCaseManagementEnabled && shouldEnableCaseManagement

  const shouldShowSendToOntic =
    isSendToOnticFeatureEnabled && shouldEnableSendToOntic

  return (
    <Menu closeOnBlur={true} gutter={0} isLazy={true} matchWidth={false}>
      <MenuButton disabled={isSendingToOntic} pointerEvents='none'>
        <Button
          _active={{ bgColor: '#5f96f5', color: '#fff' }}
          _disabled={{
            opacity: 0.4,
            cursor: 'not-allowed',
            _hover: {
              bgColor: '#e3eefb',
            },
          }}
          _focus={{ outline: 0 }}
          _hover={{ bgColor: '#1b253f', color: '#5a6c9a' }}
          border='none'
          disabled={isSendingToOntic}
          ms='5px'
          outline='none'
          pointerEvents='auto'
          variant='secondary'
        >
          {isSendingToOntic ? (
            <Spinner
              emptyColor='gray.300'
              size='sm'
              speed='0.65s'
              thickness='3px'
            />
          ) : (
            <HiOutlineDotsHorizontal size={24} />
          )}
        </Button>
      </MenuButton>
      <MenuList p={0}>
        {shouldShowAssociateIncident && (
          <MenuSelectItem
            icon={<CiLink size={16} />}
            label='Associate Incident'
            onClick={onAssociateCaseModalOpen}
          />
        )}

        {shouldShowSendToOntic && (
          <Tooltip
            hasArrow
            isDisabled={isSendToOnticEnabled}
            label={getOnticTooltipMessage(incident)}
            placement='left'
            shouldWrapChildren
          >
            <MenuSelectItem
              icon={<IoIosSend size={16} />}
              isDisabled={!isSendToOnticEnabled}
              label='Send to Ontic'
              onClick={send}
            />
          </Tooltip>
        )}

        <Tooltip
          hasArrow
          isDisabled={isDownloadReportEnabled}
          label='Incident must be resolved to download report'
          placement='left'
          shouldWrapChildren
        >
          <MenuSelectItem
            icon={<HiOutlineDownload size={16} />}
            isDisabled={!isDownloadReportEnabled}
            label='Download Report'
            onClick={downloadReport}
            showDivider={false}
          />
        </Tooltip>
      </MenuList>
    </Menu>
  )
}
