import { useAvailableIncidentActions } from '../hooks/useAvailableIncidentActions'
import { useSetIsIncidentReal } from '../hooks/useSetIsIncidentReal'
import { IncidentTab, useTabs } from '../hooks/useTabs'
import { usePermissions } from '@/hooks/usePermissions'
import { BiWrench } from 'react-icons/bi'
import { HiOutlineShieldCheck } from 'react-icons/hi'

import { Box, Flex, useDisclosure } from '@chakra-ui/react'

import { Note } from '@/components/icons'
import { EmergencyCallButton } from '@/features/emergency-call'
import { IncidentQueryResult } from '@/graphql/generated/hooks'
import { IncidentStatus } from '@/graphql/generated/schemas'

import { AddNote } from '../add-note'
import { AssociateCaseModal } from '../associate-case'
import { DispatchGuards, GuardActionTab, RadioGuards } from '../guard-action'
import { OrderRepair } from '../order-repair'
import { Resolve } from '../resolve'
import { ResolveToggle } from '../resolve/components/ResolveToggle'
import { SOPIncidentDrawerToggle } from '../sop-incident-drawer'
import { MixpanelDataIProps } from '../types/types'
import { AdditionalOptionsButton } from './AdditionalOptionsButton'
import { AnimatedPanel } from './AnimatedPanel'
import { IncidentActionTab } from './IncidentActionTab'

interface IncidentActionsIProps {
  incident: IncidentQueryResult['data']['incident']
  mixpanelData?: MixpanelDataIProps
  refetchFeedItems?: () => void
}

export const IncidentActions = ({
  incident,
  mixpanelData,
  refetchFeedItems,
}: IncidentActionsIProps) => {
  const { shouldEnableSOPIncidentDrawerToggle } = usePermissions()
  const setIsIncidentReal = useSetIsIncidentReal()

  const device = incident?.devices[0]

  const {
    isOpen: isAssociateCaseModalOpen,
    onOpen: onAssociateCaseModalOpen,
    onClose: onAssociateCaseModalClose,
  } = useDisclosure()

  const {
    tab,
    handleAddNoteClick,
    handleDispatchClick,
    handleRadioClick,
    handleOrderRepairClick,
    handleResolveClick,
    closeTabs,
  } = useTabs({ mixpanelData })

  const onToggleChange = (real: boolean) => {
    setIsIncidentReal(incident?.id, real, true, mixpanelData)
  }

  const onActionComplete = () => {
    refetchFeedItems()
    closeTabs()
  }

  const {
    isEmergencyCallEnabled,
    isAddNoteEnabled,
    isDispatchEnabled,
    isRadioGuardsEnabled,
    isOrderRepairEnabled,
    isResolveEnabled,
    isDownloadReportEnabled,
    isSendToOnticEnabled,
    isAdditionalOptionsEnabled,
    availableTabsCount,
  } = useAvailableIncidentActions({ incident })

  return (
    <Box p='24px' pb='0'>
      <Box
        alignItems='center'
        display='flex'
        justifyContent='space-between'
        mb='15px'
      >
        {shouldEnableSOPIncidentDrawerToggle ? (
          <SOPIncidentDrawerToggle showLabel={availableTabsCount < 5} />
        ) : (
          <Flex flex={1} />
        )}
        {incident?.status === IncidentStatus.Resolved && (
          <ResolveToggle
            incidentId={incident?.id}
            isReal={incident?.real}
            mixpanelData={mixpanelData}
            onChange={onToggleChange}
            style={{
              flex: 1,
              marginRight: '20px',
              justifyContent: 'flex-end',
            }}
          />
        )}
        <Box>
          {isAddNoteEnabled && (
            <IncidentActionTab
              icon={Note}
              isSelected={tab === IncidentTab.AddNote}
              label='Add Note'
              onClick={handleAddNoteClick}
            />
          )}
          {(isDispatchEnabled || isRadioGuardsEnabled) && (
            <GuardActionTab
              isDispatchEnabled={isDispatchEnabled}
              isRadioEnabled={isRadioGuardsEnabled}
              isSelected={
                tab === IncidentTab.DispatchGuards ||
                tab === IncidentTab.RadioGuards
              }
              onDispatchClick={handleDispatchClick}
              onRadioClick={handleRadioClick}
            />
          )}
          {isOrderRepairEnabled && (
            <IncidentActionTab
              icon={BiWrench}
              isSelected={tab === IncidentTab.OrderRepair}
              label='Order Repair'
              onClick={handleOrderRepairClick}
            />
          )}
          {isEmergencyCallEnabled && (
            <EmergencyCallButton
              incident={incident}
              mixpanelData={mixpanelData}
            />
          )}
          {isResolveEnabled && (
            <IncidentActionTab
              icon={HiOutlineShieldCheck}
              isSelected={tab === IncidentTab.Resolve}
              label='Resolve'
              onClick={handleResolveClick}
            />
          )}
          {isAdditionalOptionsEnabled && (
            <AdditionalOptionsButton
              incident={incident}
              isDownloadReportEnabled={isDownloadReportEnabled}
              isSendToOnticEnabled={isSendToOnticEnabled}
              onAssociateCaseModalOpen={onAssociateCaseModalOpen}
            />
          )}
        </Box>
      </Box>
      <Box>
        {tab == IncidentTab.AddNote && (
          <AnimatedPanel>
            <AddNote
              incidentId={incident?.id}
              mixpanelData={mixpanelData}
              onActionComplete={onActionComplete}
            />
          </AnimatedPanel>
        )}
        {tab == IncidentTab.DispatchGuards && (
          <AnimatedPanel>
            <DispatchGuards
              incident={incident}
              mixpanelData={mixpanelData}
              onActionComplete={onActionComplete}
            />
          </AnimatedPanel>
        )}
        {tab == IncidentTab.RadioGuards && (
          <AnimatedPanel>
            <RadioGuards
              facilityId={incident?.facility?.id}
              incidentId={incident?.id}
              mixpanelData={mixpanelData}
              onActionComplete={onActionComplete}
            />
          </AnimatedPanel>
        )}
        {tab == IncidentTab.OrderRepair && (
          <AnimatedPanel>
            <OrderRepair
              deviceType={device?.type}
              facilityId={incident?.facility?.id}
              incidentId={incident?.id}
              incidentStatus={incident?.status}
              mixpanelData={mixpanelData}
              onActionComplete={onActionComplete}
            />
          </AnimatedPanel>
        )}
        {tab == IncidentTab.Resolve && (
          <AnimatedPanel>
            <Resolve
              incident={incident}
              mixpanelData={mixpanelData}
              onActionComplete={onActionComplete}
            />
          </AnimatedPanel>
        )}
      </Box>
      <Box bgColor='#e2e8f0' h='1px' w='full' />
      <AssociateCaseModal
        incidentId={incident?.id}
        isOpen={isAssociateCaseModalOpen}
        onClose={onAssociateCaseModalClose}
      />
    </Box>
  )
}
