import { Box } from '@chakra-ui/react'

import { Notification } from '@/graphql/appsync-notifications'

export const FlagNotificationTitle = ({
  notification,
}: {
  notification: Notification
}) => {
  return (
    <Box fontSize='16px' fontWeight='bold' noOfLines={3}>
      <Box as='span' color='#2E6CDB' data-testid='FlagNotificationTitle:title'>
        AI Operator{' '}
      </Box>
      has flagged{' '}
      <Box
        as='span'
        color='#2E6CDB'
        data-testid='FlagNotificationTitle:details'
      >
        {notification?.incidentDisplayId} {notification?.incidentName}
      </Box>{' '}
      for further review and action
    </Box>
  )
}
