import { useToasts } from '@/hooks/useToasts'

import {
  IncidentDocument,
  useSendIncidentToOnticMutation,
} from '@/graphql/generated/hooks'

export const useSendIncidentsToOntic = (incidentId: string) => {
  const { showSuccess, showError } = useToasts()
  const [sendToOntic, { loading: isSendingToOntic }] =
    useSendIncidentToOnticMutation()

  const send = async () => {
    try {
      await sendToOntic({
        variables: {
          id: incidentId,
        },
        refetchQueries: [
          {
            query: IncidentDocument,
            variables: {
              id: incidentId,
            },
          },
        ],
      })

      showSuccess('Incident sent to Ontic successfully')
    } catch (e) {
      showError(e.message)
    }
  }

  return {
    isSendingToOntic,
    send,
  }
}
