import { ApolloCache } from '@apollo/client'
import { Box } from '@chakra-ui/react'

import { RadioToggle } from '@/components/ui'
import { Notification } from '@/graphql/appsync-notifications'
import {
  UpdateNotificationMutation,
  useUpdateNotification,
} from '@/graphql/appsync-notifications'

import { getToggleColour } from '../utils/utils'
import { InboxNotification } from './InboxNotification'

const updateCache = (
  cache: ApolloCache<unknown>,
  { data }: { data: UpdateNotificationMutation }
) => {
  cache.modify({
    id: cache.identify(data?.updateNotification),
    fields: {
      read() {
        return data?.updateNotification?.read
      },
    },
  })
}

export const InboxListItem = ({
  notification,
}: {
  notification: Notification
}) => {
  const [updateNotification, { loading: isLoading }] = useUpdateNotification()
  const isRead = notification?.read

  const onToggle = async () => {
    await updateNotification({
      variables: {
        input: {
          notificationTimestamp: notification?.notificationTimestamp,
          read: !isRead,
        },
      },
      update: updateCache,
    })
  }

  const onMarkAsRead = async () => {
    await updateNotification({
      variables: {
        input: {
          notificationTimestamp: notification?.notificationTimestamp,
          read: true,
        },
      },
      update: updateCache,
    })
  }

  return (
    notification && (
      <Box display='flex' paddingRight={3} px={5} py={3}>
        <Box display='flex' flex={1} pos='relative'>
          <InboxNotification
            notification={notification}
            onMarkAsRead={onMarkAsRead}
          />
          <Box
            bgColor='#e2e8f0'
            bottom='-12px'
            h='1px'
            pos='absolute'
            w='calc(100% - 12px)'
          />
        </Box>
        <Box
          alignSelf='flex-start'
          display='grid'
          ml='10px'
          placeItems='center'
        >
          <RadioToggle
            disabled={isLoading}
            isActive={!isRead}
            onClick={onToggle}
            toggleColour={getToggleColour(notification)}
            tooltipLabel={`Mark as ${isRead ? 'unread' : 'read'}`}
          />
        </Box>
      </Box>
    )
  )
}
