import { IncidentQuery } from '@/graphql/generated/operations'
import { IncidentStatus } from '@/graphql/generated/schemas'

export const getOrigin = () =>
  typeof window !== 'undefined' && window.location.origin
    ? window.location.origin
    : ''

export const getTabColors = (isSelected: boolean) => {
  const bgColor = isSelected ? '#5f96f5' : '#e3eefb'
  const hoverBgColor = isSelected ? '#5f96f5' : '#1b253f'
  const iconColor = isSelected ? '#ffffff' : '#3d7fce'
  const hoverIconColor = isSelected ? '#ffffff' : '#5a6c9a'

  return { bgColor, hoverBgColor, iconColor, hoverIconColor }
}

export const getOnticTooltipMessage = (
  incident: IncidentQuery['incident']
): string => {
  if (!incident) return ''

  const isResolved = incident.status === IncidentStatus.Resolved

  if (!isResolved) {
    return 'Incident must be resolved to send to Ontic'
  }

  if (incident.isSentToOntic) {
    return 'Incident has already been sent to Ontic'
  }

  return '' // No message needed when button is enabled
}
