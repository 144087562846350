import { Document, Page } from '@react-pdf/renderer'

import { TimelineOrder } from '../../feed/types/types'
import { getActivityTimeline } from '../../feed/utils/utils'
import { styles } from '../styles/styles'
import { DownloadReportButtonIProps } from '../types/types'
import { getIncidentDescription } from '../utils/utils'
import { Attachments } from '../views/Attachments'
import { Details } from '../views/Details'
import { EditableFields } from '../views/EditableFields'
import { Footer } from '../views/Footer'
import { Header } from '../views/Header'
import { Resolution } from '../views/Resolution'

export const ReportTemplate = ({ incident }: DownloadReportButtonIProps) => {
  const actions = incident?.actions || []
  const events = incident?.events || []
  const audioMessages =
    incident?.audioMessages?.edges?.map((a) => a?.node) || []
  const notes = incident?.notes?.edges?.map((a) => a?.node) || []

  const timeline = getActivityTimeline(
    actions,
    events,
    audioMessages,
    notes,
    TimelineOrder.Asc
  )

  const device = incident?.devices[0]

  const incidentDescription = getIncidentDescription(
    incident?.type,
    incident?.description,
    incident?.facility?.name,
    incident?.floor?.name,
    incident?.floor?.id,
    device?.name,
    device?.type,
    device?.id
  )

  return (
    <Document style={styles.document}>
      <Page style={styles.page}>
        <Header incident={incident} />
        <Resolution incident={incident} />
        <Details
          createdAt={incident?.createdAt}
          incidentDescription={incidentDescription}
          timeline={timeline}
        />
        <Attachments incident={incident} notes={notes} />
        <EditableFields />
        <Footer />
      </Page>
    </Document>
  )
}
