import { Box } from '@chakra-ui/react'

import { NotificationSubtitle } from '@/features/notification'
import { Notification } from '@/graphql/appsync-notifications'

export const FlagNotification = ({
  notification,
  onClick,
}: {
  notification: Notification
  onClick: () => void
}) => {
  return (
    <Box cursor='pointer' flex={1} onClick={onClick}>
      <Box
        data-testid='FlagNotification:header'
        fontSize='13.5px'
        fontWeight='600'
        mb={1.5}
        noOfLines={2}
      >
        AI Operator has flagged{' '}
        <Box as='span' color='#CC0000'>
          {notification.incidentDisplayId} {notification.incidentName}
        </Box>{' '}
        for further review and action
      </Box>
      <NotificationSubtitle
        facilityShortName={notification?.facilityShortName}
        fontSize='11px'
        sourceName={notification?.incidentSourceName}
        timestamp={notification?.notificationTimestamp}
      />
    </Box>
  )
}
