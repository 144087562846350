import { Box, keyframes, usePrefersReducedMotion } from '@chakra-ui/react'

import { Notification, NotificationType } from '@/graphql/appsync-notifications'

import { AudioNotification } from './AudioNotification'
import { ClearNotification } from './ClearNotification'
import { FlagNotification } from './FlagNotification'
import { IncidentNotification } from './IncidentNotification'

const rightLeft = keyframes`
  from { transform: translateX(100%); }
  to {  transform: translateX(0); }
`

export const NotificationToasts = ({
  data,
  onClick,
  onCancel,
}: {
  data: Notification[]
  onClick: (incidentId: string, incidentTimeStamp: string) => void
  onCancel: (incidentTimeStamp: string) => void
  toastList?: []
}) => {
  const isPrefersReducedMotion = usePrefersReducedMotion()
  const animation = isPrefersReducedMotion
    ? undefined
    : `${rightLeft} 0.3s ease-in-out`

  const getNotificationType = (notification: Notification) => {
    switch (notification?.notificationType) {
      case NotificationType.IncidentNew:
        return (
          <IncidentNotification
            notification={notification}
            onCancel={onCancel}
          />
        )
      case NotificationType.IncidentClear:
        return (
          <ClearNotification notification={notification} onCancel={onCancel} />
        )
      case NotificationType.AudiomessageNew:
        return (
          <AudioNotification notification={notification} onCancel={onCancel} />
        )
      case NotificationType.IncidentFlag:
        return (
          <FlagNotification notification={notification} onCancel={onCancel} />
        )
      default:
        return null
    }
  }

  return (
    <Box bottom='60px' pos='fixed' right='50px'>
      {data.map((d) => (
        <Box
          animation={animation}
          cursor='pointer'
          key={d?.notificationTimestamp}
          mb='10px'
          onClick={() => {
            onClick(d?.incidentId, d?.notificationTimestamp)
          }}
          pointerEvents='auto'
          pos='relative'
          w='400px'
          zIndex='docked'
        >
          {getNotificationType(d)}
        </Box>
      ))}
    </Box>
  )
}
