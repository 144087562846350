import { Text, View } from '@react-pdf/renderer'

import { SystemAction } from '@/graphql/generated/schemas'
import { formatDateTime } from '@/utils/formatDateTime'
import { getAiOrCreatorRoleName } from '@/utils/formats'

import { NonActionItem } from '../../feed/types/types'
import { styles } from '../styles/styles'
import { ReportDetailsIProps } from '../types/types'
import {
  getActionPerformed,
  isAudioOrNote,
  isNotAssignment,
  shouldDisplayDateForAction,
  userAction,
} from '../utils/utils'

export const Details = ({
  timeline,
  incidentDescription,
  createdAt,
}: ReportDetailsIProps) => {
  return (
    <View>
      <View>
        <View>
          <Text style={{ ...styles.mt5, ...styles.bold }}>Details</Text>
          <View style={styles.fs12}>
            <Text style={styles.mt5}>
              {formatDateTime(createdAt, 'HH:mm:ss | MM-dd-yyyy')}
            </Text>
            <Text>{incidentDescription}</Text>
          </View>
        </View>

        {timeline.length > 0 &&
          timeline.map((action, i) => {
            return (
              <View key={i} style={styles.fs12}>
                {shouldDisplayDateForAction(action) && (
                  <Text style={styles.mt5}>
                    {formatDateTime(action.createdAt, 'HH:mm:ss | MM-dd-yyyy')}
                  </Text>
                )}
                {isNotAssignment(action) && (
                  <Text>
                    {(action as unknown as SystemAction).systemMessage}
                  </Text>
                )}
                {userAction(action) && (
                  <View>
                    <Text>
                      {getAiOrCreatorRoleName(userAction(action).creator)}{' '}
                      {userAction(action).creator?.firstName +
                        ' ' +
                        userAction(action).creator?.lastName}{' '}
                      {isAudioOrNote &&
                        getActionPerformed(
                          action?.__typename as unknown as NonActionItem
                        )}
                      {action.__typename === 'IncidentAction' &&
                        getActionPerformed(action?.type)}
                    </Text>
                    {action.__typename === NonActionItem.IncidentNote && (
                      <View>
                        <Text style={{ ...styles.mt2, ...styles.ml2 }}>
                          {action?.message}
                        </Text>
                        {action?.media?.length > 0 && (
                          <Text
                            style={{
                              ...styles.mt2,
                              ...styles.ml2,
                              ...styles.fs12,
                            }}
                          >
                            {action.media.length} file(s) were attached
                          </Text>
                        )}
                      </View>
                    )}
                  </View>
                )}
              </View>
            )
          })}
      </View>
    </View>
  )
}
